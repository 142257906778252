import React from 'react'
import NewsItems from './NewsItems'

const Press = () => {
  return (
    <section className="py-0 bg-dark" id="press">
      <div className="container bg-primary !py-0">
        <div className="max-w-3xl">
          <h2 className="border-b-2 border-red-400">Press</h2>
          <NewsItems />
        </div>
      </div>
    </section>
  )
}

export default Press
