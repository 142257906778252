import React from 'react'

const news = [
  {
    date: 'Oct 21, 2020',
    title: "All the President’s spam",
    href: 'https://www.abc.net.au/news/2020-10-22/donald-trumps-campaign-email-blitz/12781782?nw=0',
  },
  {
    date: 'Oct 17, 2020',
    title: "Political campaign emails contain dark patterns to manipulate donors, voters",
    href: 'https://www.zdnet.com/article/political-campaign-emails-contain-dark-patterns-to-manipulate-donors-voters/',
  },
  {
    date: 'Oct 9, 2020',
    title: "Trump's Campaign Lures Donors With Absurd Financial Promises — and Insults",
    href: 'https://www.washingtonpost.com/outlook/trumps-campaign-lures-donors-with-absurd-financial-promises--and-insults/2020/10/09/ff3ea62c-08ef-11eb-9be6-cf25fb429f1a_story.html',
  },
  {
    date: 'Oct 9, 2020',
    title: "How campaigns use manipulative tricks to convince you to open their emails",
    href: 'https://www.fastcompany.com/90560428/political-emails-trump-dark-patterns',
  },
  {
    date: 'Oct 6, 2020',
    title: "Help, My Candidate Is Dying: Most Campaign Emails Are Manipulative, Study Findss",
    href: 'https://www.vice.com/en/article/k7qm9y/campaign-political-emails-study-princeton-university',
  },
]

const NewsItems = () => {
  return (
    <div className="max-w-lg">
      {news.map((item) => (
        <div className="py-6" key={item.title}>
          <p className="text-xs font-semibold text-gray-500 uppercase">{item.date}</p>
          <p className="mt-2 text-lg">{item.title}</p>
          <a href={item.href} className="text-sm font-semibold uppercase transition" target="_blank" rel="noopener">
            Read Article
          </a>
        </div>
      ))}
    </div>
  )
}

export default NewsItems
